@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cocon";
  src: url(../assets/fonts/CoconRegularFont.otf);
}

@font-face {
  font-family: "Gallient";
  src: url(../assets/fonts/Gallient.ttf);
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
}

:root {
  --primary-color: #21b1e5;
  --primary-naiaPage-color: #0abbb5;
  --primary-alhussni-color: #d7c57f;
  --section-color: #778692;
  --font-main: 16px;
  --font-medium: 26px;
  --font-large: 36px;
  --font-xlarge: 52px;
  --font-hug: 65px;
  /* 
  --font-main: 1.25em;
  --font-medium: 2.125em;
  --font-large: 2.5em;
  --font-xlarge: 4em;
  --font-hug: 6em; */
}

.borderForContactMobile {
  background-image: url(../assets/icons/borderContactMobile.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-inline: auto;
  width: 450px;
  height: 600px;
  margin-top: 40px;
  padding: 5px;
}

.con {
  max-width: 1700px;
  margin-inline: auto;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  width: 100%;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-Montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-Gallient {
  font-family: "Gallient";
}

.font-cocon {
  font-family: Cocon;
}

.font-bebas {
  font-family: "Bebas Neue", sans-serif;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.font-Cinzel {
  font-family: "Cinzel Decorative", serif;
}

.font-french {
  font-family: "IM Fell French Canon", serif;
}

.font-nunito {
  font-family: "Nunito", sans-serif;
}

.font-Playfair {
  font-family: "Playfair Display", serif;
}

.font-jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.bottlingPlacesImg:hover > div:last-child {
  transform: translateY(0);
}

.shadow {
  box-shadow: 5px 0px 15px 0px #00000080;
}

.shadowBrown {
  box-shadow: -40px 100px 70px 0px #7a4f4f12;
  box-shadow: 40px 0px 70px 0px #7b434312;
}

.swiper {
  width: 100%;
  height: 100% !important;
  align-items: center;
}

.rotate {
  transform: rotateY(180deg);
}

.swiper-slide {
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.alhussni .swiper-pagination-bullet {
  position: absolute;
  left: 0 !important;
  background-color: transparent;
  position: relative;
  margin: 0 0.8rem;
  padding: 0.5rem;
}

.alhussni .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  left: calc(50% - 0.4rem);
  top: 50%;
  width: 1rem;
  height: 0.2rem;
  background-color: antiquewhite;
  border-radius: 50%;
}

.alhussni .swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  top: calc(50% - 0.4rem);
  left: 50%;
  width: 0.2rem;
  height: 1rem;
  background-color: antiquewhite;
  border-radius: 50%;
}

.naiaSlider .swiper-button-next {
  width: 3rem !important;
  height: 3rem !important;
  border: 1px solid black;
  border-radius: 10000px;
  right: 5%;
}

@media screen and (max-width: 768px) {
  .naiaSlider .swiper-button-next {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 1px solid black;
    border-radius: 10000px;
    top: 10% !important;
  }
}

.furniture .sliderGalaryFurniture {
  overflow-y: visible !important;
  height: 520px !important;
}

.furniture .swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 300px;
  max-height: 400px;
  background-color: transparent;
}

.furniture .swiper-button-prev,
.furniture .swiper-button-next {
  position: absolute !important;
  top: 92%;
  margin-right: 40%;
  margin-left: 40%;
}

.furniture .swiper-button-prev:after,
.furniture .swiper-button-next:after {
  color: #fff;
  background-color: #e89f71;
  font-size: 16px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 10000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.furniture .swiper-pagination {
  top: 95% !important;
}

.furniture .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.furniture .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #e89f71 !important;
  opacity: 1;
  position: relative;
}

.contact {
  background-image: url(../assets/images/map.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 250px;
  max-height: 900px;
  margin-inline: auto;
}

.round {
  border-radius: 0px 170px 0px 156px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadein {
  animation: fade-in 1s;
}

@media screen and (max-width: 1440px) {
  :root {
    --font-medium: 20px;
    --font-large: 32px;
    --font-xlarge: 45px;
    --font-hug: 55px;
  }
}

@media screen and (max-width: 1250px) {
  :root {
    --font-large: 34px;
    --font-xlarge: 40px;
    --font-hug: 55px;
  }
}

@media screen and (max-width: 992px) {
  :root {
    --font-main: 16px;
    --font-medium: 20px;
    --font-large: 28px;
    --font-xlarge: 35px;
    --font-hug: 50px;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --font-main: 16px;
    --font-xlarge: 24px;
    --font-large: 20px;
    --font-medium: 18px;
    --font-hug: 24px;
  }

  .shadowForMenu {
    box-shadow: 2px 4px 8px 0px #cde6f2 inset;
  }
}

@media screen and (max-width: 640px) {
  :root {
    --font-main: 14px;
    --font-xlarge: 24px;
    --font-large: 18px;
    --font-medium: 16px;
    --font-hug: 24px;
  }

  .borderForContactMobile {
    width: 300px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 640px) {
  :root {
    --font-main: 12px;
    --font-xlarge: 20px;
    --font-large: 16px;
    --font-medium: 14px;
    --font-hug: 24px;
  }
  .furniture .swiper-button-prev,
  .furniture .swiper-button-next {
    margin-right: 10%;
    margin-left: 10%;
  }
}
